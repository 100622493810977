import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from "@material-ui/core";
import { useFormik } from 'formik';
import { GetClientCaseId, GetUserId } from "../../../../Functions/General";
import Axios from "axios";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function NotifyAttorney(){
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("Sending");
    const client_case_id = GetClientCaseId();
    const user_id = GetUserId();
    
    const handleModalState = () => {
        setOpen(!open);
    };
    
    const handleSubmit = async (values) => {
        await Axios.post('/api/v2/documents/notify', values)
        .then((res) => {
            setMessage('Lawyers Notified!');
            setOpen(true);
        })
        .catch(err => setMessage('Server Error!'))
        .finally(() => {formik.setSubmitting(false)});
    };
    
    const formik = useFormik({
        initialValues:{
            message: '',
            client_case_id: client_case_id,
            user_id: user_id
        },
        onSubmit: handleSubmit
    });
    return(
        <>
            <button className="btn btn-primary" onClick={() => formik.handleSubmit()} disabled={formik.isSubmitting}>
                {formik.isSubmitting ? <div className="spinner-border spinner-border-sm"></div> : "Notify Lawyer"}
            </button>
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={() => handleModalState()}
                message={message}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleModalState()}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
            <Dialog maxWidth="md" fullWidth>
                <DialogTitle>Notify Lawyer on Uploaded Documents</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent dividers>
                        <div className="d-flex flex-column">
                            <label className="h4">Message</label>
                            <TextField {...formik.getFieldProps("message")} multiline minRows={4} variant="outlined"/>
                        </div>
                    </DialogContent>
                    <DialogActions className="p-2 d-flex flex-row justify-content-start">
                        <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <div className="spinner-border spinner-border-sm"></div>: "Submit"}
                        </button>
                        <button type="button" className="btn btn-danger" disabled={formik.isSubmitting}>Cancel</button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};