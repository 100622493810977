
import MomentUtils from "@date-io/moment";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  NativeSelect
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from "yup";
import { FillContext } from "../../../../../hooks/ContextStore";
import { useContext } from "react";

const validationSchema = yup.object({
  balance: yup.number().test(
    "maxDigits",
    "Current Balance field must be 10 digits or less",
    (balance) => String(balance).length <= 10
  )
  .required("Current Balance is required!"),

  spouse_sp: yup.number().test(
    "maxDigits",
    "Spouse’s separate property field must be 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Spouse’s separate property is required!"),

  account_number: yup.string()
                  .nullable()
                  .matches(/^[0-9]+$/, "Must be only digits")
                  .min(4, 'Must be exactly 4 digits')
                  .max(4, 'Must be exactly 4 digits'),

  client_sp: yup.number().test(
    "maxDigits",
    "Client’s separate property field must be 10 digits or less",
    (balance) => String(balance).length <= 10
  )

  .required("Client’s separate property is required!"),
});

function Create(props){
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const {client_case_id,setBanks} = props;
    const [accountBalanceDate, setAccountBalanceDate] = React.useState(new Date());
    const [fill,setFill] = useContext(FillContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
  
    const handleAccountBalanceDateChange = (date) => {
      let date_dt = new Date(date);
      let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
      setAccountBalanceDate(new_date);
    };

    const onSubmit = async (values) => {
      setLoading(true);
      const formObj ={
          client_case_id: client_case_id ? client_case_id : null,
          balance_date:accountBalanceDate,
          institution_name:values.institution_name,
          account_name:values.account_name,
          account_number:values.account_number,
          type:values.type,
          withdrawal_card_name:'null',
          balance:values.balance,
          other_liens:values.other_liens,
          client_sp:values.client_sp,
          spouse_sp:values.spouse_sp,
          to_client:values.to_client,
          cost:values.cost,
          notes:values.notes,
      };

      const response = await Axios
        .post("api/estate/bank/account", formObj)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });
  
      if (response) {
        setOpen(false);
        setBanks(response.data.data);
        setFill({...fill, bank_account: 1});
        formik.resetForm();
        setTimeout(()=>{
          setLoading(false);
        }, 1500);
      }
    };

    const formik = useFormik({
      initialValues: {
        institution_name:'',
        account_name:'',
        account_number:'',
        type:'savings',
        balance:0,
        other_liens:0,
        client_sp:0,
        spouse_sp:0,
        to_client:0,
        cost:0,
        notes:'',
      },
      validateOnBlur: true,
      onSubmit,
      validationSchema
    });


    return(
        <>
            <button type="button" className="btn btn-primary ml-auto" onClick={handleClickOpen}>Add</button>
            <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Bank Accounts"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Name of Bank</Form.Label>
                      <input
                        type="text"
                        name="institution_name"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.institution_name}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.institution_name ? formik.errors.institution_name : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Account number (last 4 digits only)</Form.Label>
                      <input
                        type="text"
                        name="account_number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.account_number}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.account_number ? formik.errors.account_number : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Type of Account</Form.Label>
                       <NativeSelect
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            inputProps={{
                              name: 'type',
                            }}
                           defaultValue={formik.values.type}
                           onChange={formik.handleChange}
                           onblur={formik.handleBlur}
                          >
                          <option value="checking" selected>checking</option>
                          <option value="savings">savings</option>
                          <option value="money market">money market</option>
                          <option value="certificate of deposit">certificate of deposit</option>
                          </NativeSelect>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Name(s) on Account</Form.Label>
                      <input
                        type="text"
                        name="account_name"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.account_name}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.account_name ? formik.errors.account_name : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current account balance as of</Form.Label>
                      <MuiPickersUtilsProvider   utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={accountBalanceDate}
                              onChange={handleAccountBalanceDateChange}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>
                      <Form.Label>Current Balance</Form.Label>
                      <input
                        type="number"
                        name="balance"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.balance}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.balance ? formik.errors.balance : ""}
                        </div>
                      </div>
                    </div>
                    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Client’s separate property</Form.Label>
                      <input
                        type="number"
                        name="client_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.client_sp ? formik.errors.client_sp : ""}
                        </div>
                      </div>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Spouse’s separate property</Form.Label>
                      <input
                        type="number"
                        name="spouse_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                        </div>
                      </div>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Notes</Form.Label>
                      <input
                        type="text"
                        name="notes"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.notes}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.notes ? formik.errors.notes : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Submit</span>
                          {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                          )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
    )
}
export default Create