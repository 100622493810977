import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { EncryptStorage } from 'encrypt-storage';
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { EstateContext } from "../../../../../hooks/ContextStore";

function EstateDivision({division, storePayment, achievePayment,desiredClient,
    setDesiredClient,
    desiredSpouse,
    setDesiredSpouse,clientPrntg,
    toClient,
    toClientOff,
    toSpouseOff,
    setAmountDesiredClient,
    setAmountDesiredSpouse,
    setToClient,
    setToClientOff,
    setToSpouseOff,
    setClientPrntg}){ 
        const [open, setOpen]                                   = useState(false); 
        const mobile                                            = window.matchMedia("(max-width: 768px)");
        const [netValue, setNetValue]                           = useState();
        const [debt, setDebt]                                   = useState();
        const [assetValue, setAssetValue]                       = useState();
        const [clientSp, setClientSP]                           = useState();
        const [spouseSp, setSpouseSP]                           = useState();
        const [toSpouse, setToSpouse]                           = useState(0);
        const [spousePrntg, setSpousePrntg]                     = useState();
        const encryptStorage                                    = new EncryptStorage("secret-key");
        const decypt_data                                       = encryptStorage.getItem("case_details");   
        const [assocChoice, setAssocChoice]                     = useState([]);
        const [achievedAssoc, setAchievedAssoc]                 = useState();
        const [category,setCategory]                           = useContext(EstateContext);
        const data                                              = category ? category : [];
        const [disable, setDisable]                             = useState(true);

    const initialVal = () => {
        return {
        client_case_id: data ? data.client_case_id : false,
        real_property: data ? Boolean(data.real_property) : false,
        mineral_interest: data ? Boolean(data.mineral_interest) : false,
        cash: data ? Boolean(data.cash) : false,
        cash_equivalent: data ? Boolean(data.equivalents) : false,
        financial_institution: data ? Boolean(data.bank_account) : false,
        brokerage: data ? Boolean(data.brokerage) : false,
        public_stock: data ? Boolean(data.public_stocks) : false,
        stocks_option: data ? Boolean(data.employee_stock_option) : false,
        employee_stock: data ? Boolean(data.employee_stock) : false,
        bonus: data ? Boolean(data.bonuses) : false,
        closely_held_business: data ? Boolean(data.business_interest) : false,
        defined_contribution_plan: data ? Boolean(data.contribution_plan) : false,
        defined_benefit_plan: data ? Boolean(data.benefit_plan) : false,
        ira_sep: data ? Boolean(data.ira_sep) : false,
        military_benefit: data ? Boolean(data.military_benefit) : false,
        non_qualified_plan: data ? Boolean(data.non_qualified_plan) : false,
        government_benefit: data ? Boolean(data.government_benefits) : false,
        other_deferred_benefit: data ? Boolean(data.compensation_benefits) : false,
        union_benefits: data ? Boolean(data.union_benefits) : false,
        life_insurance: data ? Boolean(data.life_insurance) : false,
        annuity: data ? Boolean(data.annuities) : false,
        vehicles: data ? Boolean(data.vehicles) : false,
        money_owed: data ? Boolean(data.money_owned) : false,
        client_possession: data ? Boolean(data.client_possession) : false,
        spouse_possession: data ? Boolean(data.spouse_possession) : false,
        both_possession: data ? Boolean(data.spouse_both) : false,
        pets: data ? Boolean(data.pets) : false,
        live_stock: data ? Boolean(data.livestock) : false,
        club_membership: data ? Boolean(data.club_membership) : false,
        travel_award_benefit: data ? Boolean(data.travel_award_benefit) : false,
        intellectual_property: data ? Boolean(data.intellectual_property) : false,
        precious_metals: data ? Boolean(data.precious_metals) : false,
        safe_deposit_box: data ? Boolean(data.safe_deposit) : false,
        contingent_assets: data ? Boolean(data.contingent_assets) : false,
        other_assets: data ? Boolean(data.other_assets) : false,
        storage_facility: data ? Boolean(data.storage_facilities) : false,
        credit_card: data ? Boolean(data.credit_card) : false,
        tax_liabilities: data ? Boolean(data.federal_liabilities) : false,
        contigent_liabilities: data
            ? Boolean(data.contingent_liabilities)
            : false,
        other_liabilities: data ? Boolean(data.other_liabilities) : false,
        child_account: data ? Boolean(data.children_assets) : false,
        asset_held_for_benefit: data
            ? Boolean(data.asset_held_for_benefit)
            : false,
        asset_held_for_either_party: data
            ? Boolean(data.asset_held_for_either_party)
            : false,
        equitable_claim_marital: data
            ? Boolean(data.equitable_for_community_estate)
            : false,
        equitable_claim_either: data
            ? Boolean(data.equitable_for_separate_estate)
            : false,
        amount_owed: data
            ? Boolean(data.amount_owned_to_attorney_or_professionals)
            : false,
        };
    };
    
    useEffect(()=>{
        var assocCollection = [];
        let selectedAssoc   = [];
        
        for(const [key, value] of Object.entries(initialVal())){
            if(value){
                selectedAssoc.push(key);
            }
        }

        var index = 0;
        for(var key in division) {
            if(selectedAssoc.includes(key)){
                assocCollection[index] = key;
                index = index + 1;
            }
        }
        

        var net_value     = 0;
        var asset_value   = 0;
        var debt          = 0;
        var client_sp     = 0;
        var spouse_sp     = 0;
        var to_client     = 0;  

        assocCollection.map((assoc, index)=>{
            let liabilities      = ['credit_card', 'tax_liabilities', 'amount_owed', 'other_liabilities'];
            let special          = ['precious_metals'];
            let specialLiabities = ['contigent_liabilities'];
            
            if(assoc === 'vehicles'){
                net_value    = net_value   + parseFloat(division[assoc].sum('division_net_value'));
                asset_value  = asset_value + parseFloat(division[assoc].sum('asset_value'));
                debt         = debt        + parseFloat(division[assoc].sum('balance_loan'));
                client_sp    = client_sp   + parseFloat(division[assoc].sum('client_sp'));
                spouse_sp    = spouse_sp   + parseFloat(division[assoc].sum('spouse_sp'));
                to_client    = to_client   + parseFloat(division[assoc].sum('to_client'));
            }
            
            if(specialLiabities.includes(assoc)){
                net_value    = net_value   + -Math.abs(parseFloat(division[assoc].sum('net_value')));
                // asset_value  = asset_value + parseFloat(division[assoc].sum('value'));
                debt         = debt        + parseFloat(division[assoc].sum('amount'));
                client_sp    = client_sp   + -Math.abs(parseFloat(division[assoc].sum('client_sp')));
                spouse_sp    = spouse_sp   + -Math.abs(parseFloat(division[assoc].sum('spouse_sp')));
                to_client    = to_client   + -Math.abs(parseFloat(division[assoc].sum('to_client')));
                return
            } 
            
            if(special.includes(assoc)){
                net_value    = net_value   + parseFloat(division[assoc].sum('division_net_value'));
                asset_value  = asset_value + parseFloat(division[assoc].sum('asset_value'));
                debt         = debt        + parseFloat(division[assoc].sum('debt'));
                client_sp    = client_sp   + parseFloat(division[assoc].sum('client_sp'));
                spouse_sp    = spouse_sp   + parseFloat(division[assoc].sum('spouse_sp'));
                to_client    = to_client   + parseFloat(division[assoc].sum('to_client'));
                return
            }

            if(liabilities.includes(assoc)){
                net_value    = net_value   + -Math.abs(parseFloat(division[assoc].sum('net_value')));
                // asset_value  = asset_value + parseFloat(division[assoc].sum('asset_value'));
                debt         = debt        + Math.abs(parseFloat(division[assoc].sum('value')));
                client_sp    = client_sp   + -Math.abs(parseFloat(division[assoc].sum('client_sp')));
                spouse_sp    = spouse_sp   + -Math.abs(parseFloat(division[assoc].sum('spouse_sp')));
                to_client    = to_client   + -Math.abs(parseFloat(division[assoc].sum('to_client')));
                return
            }

            if(!liabilities.includes(assoc) && assoc !== 'contigent_liabilities' && assoc !== 'precious_metals' && assoc !== 'vehicles'){
                net_value    = net_value   + parseFloat(division[assoc].sum('division_net_value'));
                asset_value  = asset_value + parseFloat(division[assoc].sum('asset_value'));
                debt         = debt        + parseFloat(division[assoc].sum('debt'));
                client_sp    = client_sp   + parseFloat(division[assoc].sum('client_sp'));
                spouse_sp    = spouse_sp   + parseFloat(division[assoc].sum('spouse_sp'));
                to_client    = to_client   + parseFloat(division[assoc].sum('to_client'));
                return
            }
        });
        
        var clientPrcnt = Math.round(to_client / net_value * 100 * 100) / 100;
        var spousePrcnt = Math.round((100 - clientPrcnt) * 100) / 100;
        setAssetValue(Math.round(asset_value * 100) / 100);
        setNetValue(Math.round(net_value * 100) / 100);
        setDebt(Math.round(debt * 100) / 100);
        setClientSP(Math.round(client_sp * 100) / 100);
        setSpouseSP(Math.round(spouse_sp * 100) / 100);
        setToClient(Math.round(to_client * 100) / 100); 
        setToSpouse(Math.round((net_value - to_client) * 100) / 100); 
        setClientPrntg(clientPrcnt);
        setSpousePrntg(spousePrcnt);

    },[division, initialVal()])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitPayment = ()=>{
        storePayment({client_case_id: decypt_data.case.id, to_client: toClientOff})
        handleClose();
        setDesiredClient(0);
        setDesiredSpouse(0);
        setAmountDesiredClient(0);
        setAmountDesiredSpouse(0);
        setToClient(0);
        setToClientOff(0);
        setToSpouseOff(0);
        setClientPrntg(0);
        setAchievedAssoc();
        setAssocChoice();
    }
    
    useEffect(()=>{
        desiredSpouseValueBlur()
    },[]);

    const isNullOrEmpty = (val) => {
        return val === null || val === "" ? "" : val;
    };
    
    const getInitial = (val) => {
        return val === null || val === "" ? "" : String(val).charAt(0).toUpperCase()
    };
    
    const getAssocTitle = (assoc) => {
        let properTitle = assoc;
        if(assoc == "real_property"){
            properTitle = "Real Property";
        }
        if(assoc == "mineral_interest"){
            properTitle = "Mineral Interest";
        }
        if(assoc == "cash"){
            properTitle = "Cash";
        }
        if(assoc == "cash_equivalent"){
            properTitle = "Equivalents";
        }
        if(assoc == "financial_institution"){
            properTitle = "Bank Accounts";
        }
        if(assoc == "brokerage"){
            properTitle = "Brokerage";
        }
        if(assoc == "public_stock"){
            properTitle = "Publicly Traded, Stocks, Bonds, and Other Securities";
        }
        if(assoc == "stocks_option"){
            properTitle = "Employee Stock Options";
        }
        if(assoc == "employee_stock"){
            properTitle = "Employee Stock";
        }
        if(assoc == "bonus"){
            properTitle = "Bonuses";
        }
        if(assoc == "closely_held_business"){
            properTitle = "Closely Held Business Interests";
        }
        if(assoc == "defined_contribution_plan"){
            properTitle = "Defined Contribution Plans";
        }
        if(assoc == "defined_benefit_plan"){
            properTitle = "Defined Benefit Plans";
        }
        if(assoc == "ira_sep"){
            properTitle = "IRA/SEP"
        }
        if(assoc == "military_benefit"){
            properTitle = "Military Benefits";
        }
        if(assoc == "non_qualified_plan"){
            properTitle = "Non-Qualified Plans";
        }
        if(assoc == "government_benefit"){
            properTitle = "Government Benefits";
        }
        if(assoc == "other_deferred_benefit"){
            properTitle = "Other Deferred Compensation Benefits";
        }
        if(assoc == "union_benefits"){
            properTitle = "Union Benefits";
        }
        if(assoc == "life_insurance"){
            properTitle = "Life Insurance";
        }
        if(assoc == "annuity"){
            properTitle = "Annuities";
        }
        if(assoc == "vehicles"){
            properTitle = "Motor Vehicles";
        }
        if(assoc == "money_owed"){
            properTitle = "Money Owed to Me or my Spouse";
        }
        if(assoc == "client_possession"){
            properTitle = "In Possession of Client";
        }
        if(assoc == "spouse_possession"){
            properTitle = "In Possession of Spouse";
        }
        if(assoc == "both_possession"){
            properTitle = "In Possession of Both";
        }
        if(assoc == "pets"){
            properTitle = "Pets";
        }
        if(assoc == "live_stock"){
            properTitle = "Livestocks";
        }
        if(assoc == "club_membership"){
            properTitle = "Club Memberships";
        }
        if(assoc == "travel_award_benefit"){
            properTitle = "Travel Award Benefits";
        }
        if(assoc == "intellectual_property"){
            properTitle = "Intellectual Property";
        }
        if(assoc == "precious_metals"){
            properTitle = "Precious Metals";
        }
        if(assoc == "safe_deposit_box"){
            properTitle = "Safe Deposit Boxes";
        }
        if(assoc == "contingent_assets"){
            properTitle = "Contingent Assets";
        }
        if(assoc == "other_assets"){
            properTitle = "Other Assets";
        }
        if(assoc == "storage_facility"){
            properTitle = "Storage Facilities";
        }
        if(assoc == "credit_card"){
            properTitle = "Credit Card";
        }
        if(assoc == "tax_liabilities"){
            properTitle = "Tax Liabilities";
        }
        if(assoc == "amount_owed"){
            properTitle = "Amounts Owed to Attorneys and/or professionals in this Case";
        }
        if(assoc == "other_liabilities"){
            properTitle = "Other Liabilities";
        }
        if(assoc == "contingent_liabilities"){
            properTitle = "Contingent Liabilities";
        }
        if(assoc == "child_account"){
            properTitle = "Children Account";
        }
        if(assoc == "equitable_claim_marital"){
            properTitle = "Equitable Claims in Favor of the Marital/Community Estate";
        }
        if(assoc == "equitable_claim_either"){
            properTitle = "Equitable Claims in Favor of the Either Party's separate Estate";
        }
        if(assoc == "asset_held_for_benefit"){
            properTitle = "ASSETS HELD BY EITHER PARTY FOR THE BENEFIT OF ANOTHER";
        }
        if(assoc == "asset_held_for_either_party"){
            properTitle = "ASSETS HELD BY ANOTHER FOR EITHER PARTY";
        }
        return properTitle;
    };
    
    const getAssocValueTitle = (assoc, row) => {
        let assocTitle = "";
        
        if(assoc === 'real_property'){
            assocTitle += `${isNullOrEmpty(row.street_address)}`;
        }
        
        if(assoc === 'mineral_interest'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'cash' || assoc === 'cash_equivalent'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'financial_institution'){
            assocTitle += `${isNullOrEmpty(row.institution_name)} ${String(row.type).toUpperCase()} ${row.account_number} (${getInitial(row.account_name)})`;
        }
        
        if(assoc === 'brokerage'){
            assocTitle += `${isNullOrEmpty(row.firm)} ${row.account_number} (${getInitial(row.account_name)})`;
        }
        
        if(assoc === 'public_stock'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'stocks_option'){
            assocTitle += `${isNullOrEmpty(row.name)} (${moment(row.grant_date).format("L")})`;
        }
        
        if(assoc === 'employee_stock'){
            assocTitle += `${isNullOrEmpty(row.name)} (${moment(row.grant_date).format("L")})`;
        }
        
        if(assoc === 'bonus'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'closely_held_business'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'defined_contribution_plan'){
            assocTitle += `${isNullOrEmpty(row.name)} (${getInitial(row.employee)})`;
        }
        
        if(assoc === 'defined_benefit_plan'){
            assocTitle += `${isNullOrEmpty(row.name)} (${getInitial(row.employee)})`;
        }
        
        if(assoc === 'ira_sep'){
            assocTitle += `${isNullOrEmpty(row.name)} ${isNullOrEmpty(row.account_number)} (${getInitial(row.account_name)})`;
        }
        
        if(assoc === 'military_benefit'){
            assocTitle += `${isNullOrEmpty(row.branch)}`;
        }
        
        if(assoc === 'non_qualified_plan'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'government_benefit'){
            assocTitle += `${isNullOrEmpty(row.plan)}`;
        }
        
        if(assoc === 'other_deferred_benefit'){
            assocTitle += `${isNullOrEmpty(row.plan)}`;
        }
        
        if(assoc === 'union_benefits'){
            assocTitle += `${isNullOrEmpty(row.union)}`;
        }
        
        if(assoc === 'life_insurance'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'annuity'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'vehicles'){
            assocTitle += `${isNullOrEmpty(row.name)} ${isNullOrEmpty(row.year)} ${isNullOrEmpty(row.make)}  ${isNullOrEmpty(row.model)} `;
        }
        
        if(assoc === 'money_owed'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'client_possession'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'spouse_possession'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'both_possession'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'pets'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'live_stock'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'club_membership'){
            assocTitle += `${isNullOrEmpty(row.club)}`;
        }
        
        if(assoc === 'travel_award_benefit'){
            assocTitle += `${isNullOrEmpty(row.name)} ${isNullOrEmpty(row.account_number)}`;
        }
        
        if(assoc === 'intellectual_property'){
            assocTitle += `${isNullOrEmpty(row.spouse)}`;
        }
        
        if(assoc === 'safe_deposit_box'){
            assocTitle += `${isNullOrEmpty(row.name)} ${isNullOrEmpty(row.box_number)}`;
        }
        
        if(assoc === 'storage_facility'){
            assocTitle += `${isNullOrEmpty(row.name)} ${isNullOrEmpty(row.unit_number)}`;
        }
        
        if(assoc === 'contingent_assets'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'other_assets'){
            assocTitle += `${isNullOrEmpty(row.name)}`;
        }
        
        if(assoc === 'child_account'){
            assocTitle += `${isNullOrEmpty(row.account_name)} ${isNullOrEmpty(row.name_minor)}`;
        }
        
        if(assoc === 'equitable_claim_marital'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'equitable_claim_either'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'precious_metals'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'asset_held_for_benefit'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        
        if(assoc === 'asset_held_for_either_party'){
            assocTitle += `${isNullOrEmpty(row.description)}`;
        }
        return assocTitle;
    };
    
    const desiredClientValueChange = (props)=> {
        setDesiredClient(parseFloat(props.target.value));
        if(parseFloat(props.target.value) > 100){
            setDesiredClient(99);
        }
        setDesiredSpouse(100 - props.target.value);
        let _targetValue        = parseFloat(props.target.value)
        let _clientPercentage   = isNaN(_targetValue) ? 0 : _targetValue;
        let _spousePercentage   = 100 - _clientPercentage;
        
        let _newToClient        = (_clientPercentage / 100) * netValue;
        let _newToSpouse        = (_spousePercentage / 100) * netValue; 
        
        let _clientOffset       = Math.round((_newToClient - toClient) * 100) / 100;
        let _spouseOffset       = Math.round((_newToSpouse - toSpouse) * 100) / 100; 
        
        setToClientOff(Math.round(_clientOffset));
        setToSpouseOff(Math.round(_spouseOffset));
        setClientPrntg(_clientPercentage); 
        setSpousePrntg(_spousePercentage);
        setAmountDesiredClient(Math.round(_newToClient));
        setAmountDesiredSpouse(Math.round(_newToSpouse));
        desiredSpouseValueBlur();
    }

    const desiredSpouseValueChange = (props)=> {
        setDesiredSpouse(parseFloat(props.target.value));
        if(parseFloat(props.target.value) > 100){
            setDesiredSpouse(99);
        }
        setDesiredClient(100 - props.target.value);
        let _targetValue        = parseFloat(props.target.value)
        let _spousePercentage   = isNaN(_targetValue) ? 0 : _targetValue;
        let _clientPercentage   = 100 - _spousePercentage;
        let _newToClient        = (_clientPercentage / 100) * netValue;
        let _newToSpouse        = (_spousePercentage / 100) * netValue; 
        let _clientOffset       = Math.round((_newToClient - toClient) * 100) / 100;
        let _spouseOffset       = Math.round((_newToSpouse - toSpouse) * 100) / 100; 

        setToClientOff(Math.round(_clientOffset));
        setToSpouseOff(Math.round(_spouseOffset));
        setClientPrntg(_clientPercentage); 
        setSpousePrntg(_spousePercentage);
        setAmountDesiredClient(Math.round(_newToClient));
        setAmountDesiredSpouse(Math.round(_newToSpouse));
        desiredSpouseValueBlur();
    }

    const desiredSpouseValueBlur = ()=> {
        var assocCollection = [];
        let selectedAssoc   = [];

        var toClientValNew      = 0;
        var toSpouseValNew      = 0;
        var choicesAssoc        = [];
        var availableAssoc      = [];
        var iterate             = 0;
        
        for(const [key, value] of Object.entries(initialVal())){
            if(value){
                selectedAssoc.push(key);
            }
        }

        var index = 0;
        for(var key in division) {
            if(selectedAssoc.includes(key)){
                assocCollection[index] = key;
                index = index + 1;
            }
        }

        assocCollection.map((assoc, index)=>{
            let assocValues = [];
            let assocParentTitle = "";
            let liabilities      = ['credit_card', 'tax_liabilities', 'amount_owed', 'other_liabilities', 'contigent_liabilities'];
            division[assoc].map((val)=>{ 
                var toClientVal         = parseFloat(val.to_client);
                var toSpouseVal         = parseFloat(val.division_net_value) - parseFloat(val.to_client)
                var clientOffsetAbs     = toClientOff;
                var spouseOffsetAbs     = toSpouseOff;
                var divisionNetVal      = parseFloat(val.division_net_value);

                if ( assoc !== 'payment_between_parties' && !liabilities.includes(assoc)) {
                    
                    if(clientOffsetAbs < 0){
                        if(toClientVal > +Math.abs(clientOffsetAbs)){
                            let assocTitle = getAssocValueTitle(assoc, val);
                        
                            var property            = Object.getOwnPropertyNames(val);
                            
                            iterate++;
                            assocValues.push({
                                assoc:          assoc,
                                id:             val[property[0]],
                                achieveTitle:   `${assocTitle === "" ? 'NO TITLE' : assocTitle} - CLIENT CURRENT VALUE: ${toClientVal}`,
                                to_client:      toClientVal +  toClientOff
                            });
                        }
                    }
    
                    if(spouseOffsetAbs < 0){
                        if(toSpouseVal > +Math.abs(spouseOffsetAbs)){
                            let assocTitle = getAssocValueTitle(assoc, val);
                        
                            var property            = Object.getOwnPropertyNames(val);
                            
                            iterate++;
                            assocValues.push({
                                assoc:          assoc,
                                id:             val[property[0]],
                                achieveTitle:   `${assocTitle === "" ? 'NO TITLE' : assocTitle} - SPOUSE CURRENT VALUE: ${toSpouseVal}`,
                                to_client:      toClientVal +  toClientOff
                            });
                        }
                    }
                }
            }) 
            availableAssoc.push({assoc: getAssocTitle(assoc), values: assocValues});
        });
        setAssocChoice(availableAssoc);
    }

    const filteredAssocs = useMemo(() => {
        if(Array.isArray(assocChoice) && assocChoice.length > 0){
            return assocChoice.filter((assoc) => {
                return assoc.values.length > 0;
            });
        }else{
            return [];
        }
        
    },[assocChoice]);

    console.log('FILTERED ASSOCS: ', filteredAssocs);

    useEffect(()=>{
        if(filteredAssocs.length !== 0){
            setAchievedAssoc(filteredAssocs[0].values[0])
        }
    },[filteredAssocs]);

    const submitAchieved =()=> {
        achievePayment(achievedAssoc);
        handleClose();
        setDesiredClient(0);
        setDesiredSpouse(0);
        setAmountDesiredClient(0);
        setAmountDesiredSpouse(0);
        setToClient(0);
        setToClientOff(0);
        setToSpouseOff(0);
        setClientPrntg(0);
        setAchievedAssoc();
        setAssocChoice();
    }

    const achiveOnChange = (props)=> {
        setAchievedAssoc(JSON.parse(props.target.value));
    }

    useEffect(()=>{
        if(achievedAssoc !== null && achievedAssoc !== undefined && achievedAssoc !== '' && assocChoice.length !== 0){
            setDisable(false);
        }
        console.log('ACHIEVED ASSOC: ', achievedAssoc);
    },[achievedAssoc, assocChoice]);
    return(
        <>
        <button type="button" className="ml-3 btn btn-primary" onClick={handleClickOpen} >Achieve Division</button>
            <Dialog
            fullWidth={true}
            // maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="mt-4">{"Achieve Division"}</DialogTitle>
            <hr/>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                  <form
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <Form.Label><h2 className="text-dark font-weight-bolder">Current Property Division</h2></Form.Label>
                    <div className="row">
                        <div className="col-4 form-group fv-plugins-icon-container">
                            <Form.Label>Net Value</Form.Label>
                            <br></br> 
                            <NumberFormat value={netValue} displayType={'text'} thousandSeparator={true}/>
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                        <div className="col-4 form-group fv-plugins-icon-container">
                            <Form.Label>Client</Form.Label>
                            <br></br> 
                            <NumberFormat value={clientPrntg} displayType={'text'} thousandSeparator={true}/>% (<NumberFormat value={toClient} displayType={'text'} thousandSeparator={true} prefix={'$'} />)
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                        <div className="col-4 form-group fv-plugins-icon-container">
                            <Form.Label>Spouse</Form.Label>
                            <br></br>
                            <NumberFormat value={spousePrntg} displayType={'text'} thousandSeparator={true}/>% (<NumberFormat value={toSpouse} displayType={'text'} thousandSeparator={true} prefix={'$'} />)
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mb-3"/>
                    {/* <Form.Label><h5 className="text-dark">Current Amount of Property Division</h5></Form.Label>
                    <div className="row">
                        <div className="col-6 form-group fv-plugins-icon-container">
                            <Form.Label>Client: </Form.Label>
                            <br></br>
                            <NumberFormat value={toClient} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                        <div className="col-6 form-group fv-plugins-icon-container">
                            <Form.Label>Spouse: </Form.Label>
                            <br></br>
                            <NumberFormat value={toSpouse} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <Form.Label><h5 className="text-dark">Amount Needed to Achieve Desired Property Division</h5></Form.Label>
                    <div className="row">
                        <div className="col-6 form-group fv-plugins-icon-container">
                            <Form.Label>Client: </Form.Label>
                            <br></br>
                            <NumberFormat value={toClientOff} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                        <div className="col-6 form-group fv-plugins-icon-container">
                            <Form.Label>Spouse: </Form.Label>
                            <br></br>
                            <NumberFormat value={toSpouseOff} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                    </div>
                    <Form.Label><h5 className="text-dark">Amount After Desired Property Division</h5></Form.Label>
                    <div className="row">
                        <div className="col-6 form-group fv-plugins-icon-container">
                            <Form.Label>Client: </Form.Label>
                            <br></br>
                            <NumberFormat value={amountDesiredClient} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                        <div className="col-6 form-group fv-plugins-icon-container">
                            <Form.Label>Spouse: </Form.Label>
                            <br></br>
                            <NumberFormat value={amountDesiredSpouse} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-6">
                            <Form.Label><h5 className="text-dark">Desired Division</h5></Form.Label>
                            <div className="row">
                                <div className="col-6 form-group fv-plugins-icon-container">
                                    <Form.Label>Client</Form.Label> 
                                    <br></br>
                                    <NumberFormat value={desiredClient} customInput={TextField} onChange={desiredClientValueChange} onBlur={desiredSpouseValueBlur} thousandSeparator={true}/>
                                </div>
                                <div className="col-6 form-group fv-plugins-icon-container">
                                    <Form.Label>Spouse</Form.Label>
                                    <br></br>
                                    <NumberFormat value={desiredSpouse} customInput={TextField} onChange={desiredSpouseValueChange} onBlur={desiredSpouseValueBlur} thousandSeparator={true}/>
                                </div>
                                <div className="col-6 form-group fv-plugins-icon-container">
                                    {/* <button
                                        id="kt_login_signin_submit" 
                                        className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                                        onClick={(e)=> {
                                            submitPayment();
                                            e.preventDefault();
                                        }}
                                    >
                                        <span>ACHIEVE THROUGH PAYMENT</span>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <Form.Label><h5 className="text-dark">After Desired Property Division</h5></Form.Label>
                            <div className="row">
                                <div className="col-6 form-group fv-plugins-icon-container">
                                    <Form.Label>Client: </Form.Label>
                                    <br></br>
                                    <NumberFormat value={toClient - toSpouseOff} displayType={'text'} thousandSeparator={true} prefix={'$'} /> ({toClientOff < 0 ? '' : '+'}<NumberFormat value={toClientOff < 0 ? toClientOff : `+${toClientOff}`} displayType={'text'} thousandSeparator={true} prefix={'$'} />)
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 form-group fv-plugins-icon-container">
                                    <Form.Label>Spouse: </Form.Label>
                                    <br></br>
                                    <NumberFormat value={toSpouse - toClientOff} displayType={'text'} thousandSeparator={true} prefix={'$'} /> (<NumberFormat value={toSpouseOff} displayType={'text'} thousandSeparator={true} prefix={'$'} />)
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mb-3"/>
                    <div className="form-group">
                        <button
                            id="kt_login_signin_submit" 
                            className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                            onClick={(e)=> {
                                submitPayment();
                                e.preventDefault();
                            }}
                        >
                            <span>ACHIEVE THROUGH PAYMENT</span>
                        </button>
                    </div>
                    <Form.Label><h5 className="text-dark">Achieve Division Using</h5></Form.Label>
                    <div className="form-group fv-plugins-icon-container">
                      <FormControl variant="outlined" style={{width: "100%"}}> 
                        <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                        
                        <Select
                          native
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={achiveOnChange}
                        > 
                            {
                                assocChoice && assocChoice.map((use) => (
                                    <>
                                        {use.values.length !== 0 ?
                                            <optgroup label={use.assoc}>
                                                {use.values.map(val=>(
                                                    <option value={JSON.stringify(val)}>{val.achieveTitle}</option> 
                                                ))}
                                            </optgroup>
                                        :
                                        null
                                        }
                                    </>
                                ))
                            }
                        </Select>
                      </FormControl>
                    </div>
                    
                    <div className="form-group d-flex flex-wrap align-items-center">
                      <button
                        id="kt_login_signin_submit" 
                        className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                        onClick={(e)=> {
                            submitAchieved();
                            e.preventDefault();
                        }}
                        disabled={disable}
                      >

                        <span>ACHIEVE</span>
                      </button>
                        <a onClick={handleClose}
                            className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                        >
                            <span>CANCEL</span>
                        </a>
                    </div>
                  </form>
                  
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
    )
}
export default EstateDivision