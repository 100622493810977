import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import Axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from "yup";
import { FillContext } from "../../../../../hooks/ContextStore";
import { useContext } from "react";
const validationSchema = yup.object({
  
  value: yup.number().test(
    "maxDigits",
    "Current balance field must be 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Current balance is required!"),

  other_liens: yup.number().test(
    "maxDigits",
    "Other liens field must be 10 digits or less",
    (other_liens) => String(other_liens).length <= 10
  )
  .required("Other liens is required!"),

  client_sp: yup.number().test(
    "maxDigits",
    "Client’s separate propertyfield must be 10 digits or less",
    (other_liens) => String(other_liens).length <= 10
  )
  .required("Client’s separate property is required!"),

  spouse_sp: yup.number().test(
    "maxDigits",
    "Spouse’s separate property field must be 10 digits or less",
    (other_liens) => String(other_liens).length <= 10
  )
  .required("Spouse’s separate property is required!"),

});

function Create(props){
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const {client_case_id, setContributions} = props;
    const [fill,setFill] = useContext(FillContext);
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (values) => {
      setLoading(true);
      const formObj ={
          client_case_id: client_case_id ? client_case_id : null,
          name:values.name,
          employee:values.employee,
          employer:values.employer,
          value:values.value,
          other_liens:values.other_liens,
          client_sp:values.client_sp,
          spouse_sp:values.spouse_sp,
          to_client:values.to_client,
          cost:values.cost,
          notes:values.notes,
      };

      const response = await Axios
        .post("api/estate/retirement/benefits/contribution", formObj)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });
  
      if (response) {
        setOpen(false);
        setContributions(response.data.data);
        formik.resetForm();
        setFill({...fill, benefit_plan: 1});
        setTimeout(()=>{
          setLoading(false);
        }, 1500);
      }
    };

    const formik = useFormik({
      initialValues: {
        name:'',
        employee:'',
        employer:'',
        value:0,
        other_liens:0,
        client_sp:0,
        spouse_sp:0,
        to_client:0,
        cost:0,
        notes:''
      },
      validateOnBlur: true,
      onSubmit,
      validationSchema:validationSchema
    });


    return(
        <>
            <button type="button" className="btn btn-primary ml-auto" onClick={handleClickOpen}>Add</button>
            <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Defined Contribution Plans (401(k), 403(b), etc.)"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Name of Plan</Form.Label>
                      <input
                        type="text"
                        name="name"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.name ? formik.errors.name : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Employee</Form.Label>
                      <input
                        type="text"
                        name="employee"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.employee}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.employee ? formik.errors.employee : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Employer</Form.Label>
                      <input
                        type="text"
                        name="employer"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.employer}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.employer ? formik.errors.employer : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current balance</Form.Label>
                      <input
                        type="number"
                        name="value"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.value}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.value ? formik.errors.value : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Balance of any loans</Form.Label>
                      <input
                        type="number"
                        name="other_liens"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.other_liens}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.other_liens ? formik.errors.other_liens : ""}
                        </div>
                      </div>
                    </div>

                    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Client’s separate property</Form.Label>
                      <input
                        type="number"
                        name="client_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.client_sp ? formik.errors.client_sp : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Spouse’s separate property</Form.Label>
                      <input
                        type="number"
                        name="spouse_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                        </div>
                      </div>
                    </div>
                    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Notes</Form.Label>
                      <input
                        type="text"
                        name="notes"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.notes}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.notes ? formik.errors.notes : ""}
                        </div>
                      </div>
                    </div>
    
                 
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Submit</span>
                        {loading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
    )
}
export default Create