import Link from "@material-ui/core/Link";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  EstateContext,
  SnackBarOptionsContext,
} from "../../../hooks/ContextStore";
import EstateCards from "./Components/EstateCards";
import EstateDropdown from "./Components/EstateDropdown";

import EstateCategories from "./_partials/EstateCategoriesModal/EstateCategories";
function Estate() {
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data?.case?.id : null;
  const [downloading, setDownloading] = useState(false);
  const [snackbarOptions, setSnackBarOptions] = useContext(
    SnackBarOptionsContext
  );


  const handleDownload = () => {
    setDownloading(true);

    setSnackBarOptions({
      show: true,
      title: "Archiving Large File...",
    });

    Axios.get(`/api/v2/files/estate/batch/download/${client_case_id}`)
      .then((res) => {
        setSnackBarOptions({
          show: false,
          title: "",
        });
        window.open(res.data, "_blank");
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setSnackBarOptions({
            show: true,
            title: "No Files to Download",
          });
        } else {
          setSnackBarOptions({
            show: true,
            title: "Unknown Error Occured",
          });
        }
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div id="estatePage" className="d-flex flex-column">
      <div className="col-12 p-6 bg-white border-0 d-flex mb-7 align-items-center shadow rounded">
        <div className="">
          <h3>Estate</h3>
          {/* <EstateDropdown/> */}
        </div>

        <div className="ml-auto d-flex">
          {/* <button
            type="button"
            className="btn btn-primary ml-auto"
            onClick={handleClickOpen}
          >
            Categories
          </button>
          <EstateCategories
            handleClickOpen={handleClickOpen}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
          /> */}
          <Link href="/estate-division">
            <button type="button" className="ml-3 btn custom-blue-button">
              Estate Division
            </button>
          </Link>
          <button
            type="button"
            className="ml-3 btn btn-primary d-none d-md-block"
            disabled={downloading}
            onClick={handleDownload}
          >
            Download Files{" "}
            {downloading ? (
              <span className="ml-3 spinner spinner-white p-3"></span>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>

      <div className="d-flex flex-row flex-wrap">
         {/* <EstateCard />  */}
          <EstateCards />
      </div>
    </div>
  );
}

export default Estate;
